export const isProduction = process.env.NODE_ENV === 'production';

export const NetworkContextName = 'NETWORK';

export const connectorLocalStorageKey = 'connectorId';

export enum ChainId {
  ETH_MAINNET = 1,
  ETH_ROPSTEN = 3,
  ETH_RINKEBY = 4,
  ETH_GOERLI = 5,
  ETH_KOVAN = 42,

  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
};

export const startTime = '2021-06-14T15:00:00.000Z';

export const masksAddress = '0xE97dDABfE81E3532EF6A0119463C9D12D41a962A';
export const powerAddress = '0xd6319D0d2Bc6D58066F61C1f82715564B31DD864';

export const cosmoAddress = '0x27cd7375478F189bdcF55616b088BE03d9c4339c';
export const cmpAddress = '0xB9FDc13F7f747bAEdCc356e9Da13Ab883fFa719B';

export const cosmoBscAddress = '0x60E5FfdE4230985757E5Dd486e33E85AfEfC557b';
export const cmpBscAddress = '0x7A43397662e82a9C15D590f211347D2871B12bb7';


export const optionsCharacter = [
  { key: 'all', text: 'All characters', value: 'all' },
  { key: 'alien', text: 'Alien', value: 'Alien' },
  { key: 'robot', text: 'Robot', value: 'Robot' },
  { key: 'male', text: 'Male', value: 'Male' },
  { key: 'female', text: 'Female', value: 'Female' },
];

export const optionsMask = [
  { key: 'all', text: 'All masks', value: 'all' },
  { key: '1', text: '1', value: '1' },
  { key: '2', text: '2', value: '2' },
  { key: '3', text: '3', value: '3' },
  { key: '4', text: '4', value: '4' },
  { key: '5', text: '5', value: '5' },
  { key: '6', text: '6', value: '6' },
  { key: '7', text: '7', value: '7' },
  { key: '8', text: '8', value: '8' },
  { key: '9', text: '9', value: '9' },
  { key: '10', text: '10', value: '10' },
  { key: '11', text: '11', value: '11' },
  { key: '12', text: '12', value: '12' },
  { key: '13', text: '13', value: '13' },
  { key: '14', text: '14', value: '14' },
  { key: '15', text: '15', value: '15' },
  { key: '16', text: '16', value: '16' },
  { key: '17', text: '17', value: '17' },
  { key: '18', text: '18', value: '18' },
  { key: '19', text: '19', value: '19' },
  { key: '20', text: '20', value: '20' },
  { key: '21', text: '21', value: '21' },
  { key: '22', text: '22', value: '22' },
  { key: '23', text: '23', value: '23' },
  { key: '24', text: '24', value: '24' },
  { key: '25', text: '25', value: '25' },
  { key: '26', text: '26', value: '26' },
  { key: '27', text: '27', value: '27' },
  { key: '28', text: '28', value: '28' },
  { key: '29', text: '29', value: '29' },
  { key: '30', text: '30', value: '30' },
  { key: '31', text: '31', value: '31' },
  { key: '32', text: '32', value: '32' },
  { key: '33', text: '33', value: '33' },
  { key: '34', text: '34', value: '34' },
  { key: '35', text: '35', value: '35' },
  { key: '36', text: '36', value: '36' },
  { key: '37', text: '37', value: '37' },
  { key: '38', text: '38', value: '38' },
  { key: '39', text: '39', value: '39' },
  { key: '40', text: '40', value: '40' },
  { key: '41', text: '41', value: '41' },
  { key: '42', text: '42', value: '42' },
  { key: '43', text: '43', value: '43' },
  { key: '44', text: '44', value: '44' },
  { key: '45', text: '45', value: '45' },
  { key: '46', text: '46', value: '46' },
  { key: '47', text: '47', value: '47' },
  { key: '48', text: '48', value: '48' },
  { key: '49', text: '49', value: '49' },
  { key: '50', text: '50', value: '50' },
  { key: '51', text: '51', value: '51' },
  { key: '52', text: '52', value: '52' },
  { key: '53', text: '53', value: '53' },
  { key: '54', text: '54', value: '54' },
  { key: '55', text: '55', value: '55' },
  { key: '56', text: '56', value: '56' },
  { key: '57', text: '57', value: '57' },
  { key: '58', text: '58', value: '58' },
  { key: '59', text: '59', value: '59' },
  { key: '60', text: '60', value: '60' },
  { key: '61', text: '61', value: '61' },
  { key: '62', text: '62', value: '62' },
  { key: '63', text: '63', value: '63' },
  { key: '64', text: '64', value: '64' },
  { key: '65', text: '65', value: '65' },
  { key: '66', text: '66', value: '66' },
  { key: '67', text: '67', value: '67' },
  { key: '68', text: '68', value: '68' },
  { key: '69', text: '69', value: '69' },
  { key: '70', text: '70', value: '70' },
  { key: '71', text: '71', value: '71' },
  { key: '72', text: '72', value: '72' },
  { key: '73', text: '73', value: '73' },
  { key: '74', text: '74', value: '74' },
  { key: '75', text: '75', value: '75' },
  { key: '76', text: '76', value: '76' },
  { key: '77', text: '77', value: '77' },
  { key: '78', text: '78', value: '78' },
  { key: '79', text: '79', value: '79' },
  { key: '80', text: '80', value: '80' },
  { key: '81', text: '81', value: '81' },
  { key: '82', text: '82', value: '82' },
  { key: '83', text: '83', value: '83' },
  { key: '84', text: '84', value: '84' },
  { key: '85', text: '85', value: '85' },
  { key: '86', text: '86', value: '86' },
  { key: '87', text: '87', value: '87' },
  { key: '88', text: '88', value: '88' },
  { key: '89', text: '89', value: '89' },
  { key: '90', text: '90', value: '90' },
  { key: '91', text: '91', value: '91' },
  { key: '92', text: '92', value: '92' },
  { key: '93', text: '93', value: '93' },
  { key: '94', text: '94', value: '94' },
  { key: '95', text: '95', value: '95' },
  { key: '96', text: '96', value: '96' },
  { key: '97', text: '97', value: '97' },
  { key: '98', text: '98', value: '98' },
  { key: '99', text: '99', value: '99' },
  { key: '100', text: '100', value: '100' },
];

export const optionsItem = [
  { key: 'all', text: 'All items', value: 'all' },
  { key: 'friend', text: 'Friend', value: 'Friend' },
  { key: 'history', text: 'History', value: 'History' },
  { key: 'levitation', text: 'Levitation', value: 'Levitation' },
  { key: 'teleport', text: 'Teleport', value: 'Teleport' },
  { key: 'time', text: 'Time', value: 'Time' },
];

export const optionsBackground = [
  { key: 'all', text: 'All backgrounds', value: 'all' },
  { key: '1', text: '1', value: '1' },
  { key: '2', text: '2', value: '2' },
  { key: '3', text: '3', value: '3' },
  { key: '4', text: '4', value: '4' },
  { key: '5', text: '5', value: '5' },
  { key: '6', text: '6', value: '6' },
  { key: '7', text: '7', value: '7' },
  { key: '8', text: '8', value: '8' },
  { key: '9', text: '9', value: '9' },
  { key: '10', text: '10', value: '10' },
];

export const optionsLabel = [
  { key: 'all', text: 'All labels', value: 'all' },
  { key: 'labeled', text: 'Labeled', value: 'Labeled' },
  { key: 'unlabeled', text: 'Unlabeled', value: 'Unlabeled' },
];

export const optionsExclusive = [
  { key: 'all', text: 'All characters', value: 'all' },
  { key: 'yes', text: 'Yes', value: 'Yes' },
  { key: 'no', text: 'No', value: 'No' },
];
