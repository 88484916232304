import React, { useState } from 'react';
import { Header, Modal as ModalUi } from 'semantic-ui-react';
import { ModalProps } from './types';



const Modal: React.FC<ModalProps> = ({
  title,
  actions,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = '24px',
  //headerBackground = 'transparent',
  //minWidth = '320px',
  open = true,
  ...props
}) => {
  return (
    <ModalUi
      closeIcon={!hideCloseButton}
      //minWidth={minWidth}
      {...props}
      open={open}
      onClose={onDismiss}
    >
      <ModalUi.Header style={{ backgroundColor: '#aed5f4', }}>
        <Header>{title}</Header>
      </ModalUi.Header>
      <ModalUi.Content style={{ backgroundColor: '#aed5f4', }}>{children}</ModalUi.Content>
      <ModalUi.Actions style={{ backgroundColor: '#aed5f4', }}>{actions}</ModalUi.Actions>
    </ModalUi>
  )
};

export default Modal;
